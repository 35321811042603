<template>

  <b-card-code>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row col>
          <b-col md="6">
            <b-form-group
              id="countryLabel"
              label="Country"
              label-for="mc-country"
            >
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required|alpha|max:60"
              >
                <b-form-input
                  id="country"
                  v-model="CurrencyEditForm.country"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Country"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="currencyCodeLabel"
              label="Country Code"
              label-for="mc-currencyCode"
            >
              <validation-provider
                #default="{ errors }"
                name="Currency Code"
                rules="required|max:60"
              >
                <b-form-input
                  id="currency_code"
                  v-model="CurrencyEditForm.currency_code"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Currency Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="symbolLabel"
              label="Symbol"
              label-for="mc-symbol"
            >
              <validation-provider
                #default="{ errors }"
                name="Symbol"
                rules="required|max:5"
              >
                <b-form-input
                  id="symbol"
                  v-model="CurrencyEditForm.symbol"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Symbol"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              id="rateLabel"
              label="Rate"
              label-for="mc-rate"
            >
              <validation-provider
                #default="{ errors }"
                name="Rate"
                rules="required|max:10"
              >
                <b-form-input
                  id="rate"
                  v-model="CurrencyEditForm.rate"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Rate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12" class="demo-inline-spacing">
            <b-button
              class="updateCurrencyBtn"
              :disabled="loading"
              variant="primary"
              type="submit"
              @click="updateCurrency"
            >
              Submit
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              variant="secondary"
              :to="{name: 'currency-list'}"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>

import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import {
  required, email, confirmed, alpha
} from '@core/utils/validations/validations'
import errorResponseHandler from '@/services/errorHandler'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { resConditionCheck } from '@/helpers/constant'
import CurrencyService from "../../../services/currency/currency.service"

export default {

  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,

  },

  data() {
    return {
      CurrencyEditForm: {
        country: "",
        currency_code: "",
        symbol: "",
        rate: ""

      },
      required,
      email,
      confirmed,
      alpha,
      loading: false,
      resConditionCheck
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      this.getCurrencyById(this.$route.params.id)
    }
  },

  methods: {
    async getCurrencyById(id) {
      await CurrencyService.getCurrencyById(id).then(res => {
        if (resConditionCheck(res.status) && res.data.data) {
          this.CurrencyEditForm.country = res.data.data.country
          this.CurrencyEditForm.currency_code = res.data.data.code
          this.CurrencyEditForm.symbol = res.data.data.symbol
          this.CurrencyEditForm.rate = res.data.data.liveRate
        }
      }).catch(error => {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      })
    },

    updateCurrency() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          CurrencyService.updateCurrency(this.$route.params.id, this.CurrencyEditForm).then(res => {
            if (resConditionCheck(res.status) && res.data.data) {
              this.loading = false
              this.$router.push('/currency/list')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            const errorData = errorResponseHandler(error)
            this.loading = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      })
    }

  }

}
</script>
