var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{attrs:{"col":""}},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"countryLabel","label":"Country","label-for":"mc-country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required|alpha|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"country","state":errors.length > 0 ? false:null,"placeholder":"Country"},model:{value:(_vm.CurrencyEditForm.country),callback:function ($$v) {_vm.$set(_vm.CurrencyEditForm, "country", $$v)},expression:"CurrencyEditForm.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"currencyCodeLabel","label":"Country Code","label-for":"mc-currencyCode"}},[_c('validation-provider',{attrs:{"name":"Currency Code","rules":"required|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"currency_code","state":errors.length > 0 ? false:null,"placeholder":"Currency Code"},model:{value:(_vm.CurrencyEditForm.currency_code),callback:function ($$v) {_vm.$set(_vm.CurrencyEditForm, "currency_code", $$v)},expression:"CurrencyEditForm.currency_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"symbolLabel","label":"Symbol","label-for":"mc-symbol"}},[_c('validation-provider',{attrs:{"name":"Symbol","rules":"required|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"symbol","state":errors.length > 0 ? false:null,"placeholder":"Symbol"},model:{value:(_vm.CurrencyEditForm.symbol),callback:function ($$v) {_vm.$set(_vm.CurrencyEditForm, "symbol", $$v)},expression:"CurrencyEditForm.symbol"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"rateLabel","label":"Rate","label-for":"mc-rate"}},[_c('validation-provider',{attrs:{"name":"Rate","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rate","state":errors.length > 0 ? false:null,"placeholder":"Rate"},model:{value:(_vm.CurrencyEditForm.rate),callback:function ($$v) {_vm.$set(_vm.CurrencyEditForm, "rate", $$v)},expression:"CurrencyEditForm.rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"demo-inline-spacing",attrs:{"md":"12"}},[_c('b-button',{staticClass:"updateCurrencyBtn",attrs:{"disabled":_vm.loading,"variant":"primary","type":"submit"},on:{"click":_vm.updateCurrency}},[_vm._v(" Submit "),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()]),_c('b-button',{attrs:{"variant":"secondary","to":{name: 'currency-list'}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }